import { defineComponent, ref, onMounted } from "vue";
import { useRouter } from "vue-router";
export default defineComponent({
  setup: function setup() {
    var router = useRouter();
    var index = ref(1);

    var handleGuige = function handleGuige() {
      index.value = 1;
      router.push('/introduction');
    };

    var handleRuanjian = function handleRuanjian() {
      index.value = 2;
      router.push('/software');
    };

    var handleBuy = function handleBuy() {
      index.value = 3;
      router.push('/buy');
    };

    var backTop = ref(false);

    var handleScrollx = function handleScrollx() {
      // console.log("滚动高度", window.pageYOffset);
      if (window.pageYOffset > 600) {
        backTop.value = true;
      } else {
        backTop.value = false;
      }
    };

    onMounted(function () {
      window.addEventListener("scroll", handleScrollx, true);
      handlebackTop();
    });

    var handlebackTop = function handlebackTop() {
      content.scrollIntoView();
    };

    return {
      index: index,
      backTop: backTop,
      handleGuige: handleGuige,
      handleRuanjian: handleRuanjian,
      handleBuy: handleBuy,
      handlebackTop: handlebackTop
    };
  }
});